import React, { Children, useContext, useState } from 'react';
import socketio from "socket.io-client";
const Settings = require('settings.json');

const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;

export const socket = socketio.connect(Settings[env].server_url);
export const SocketContext = React.createContext();

export const SocketProvider = ({ children }) => {

    return (
        <SocketContext.Provider value={ socket }>
            {children}
        </SocketContext.Provider>
    );

}